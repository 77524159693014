.aboutPage {
  animation: opacity-animation 1s ease;
  font-family: "Arvo", sans-serif;
  color: wheat;
}

.imageContainer {
  text-align: center;
}

.daniPhoto {
  width: 100%;
}

.daniCarouselPhotos {
  width: 60%;
}

.dandaAboutText {
  text-align: justify;
  font-size: 0.85rem;
}

@media only screen and (max-width: 767px) {
  /* .daniPhoto {
    width: 80%;
  } */

  .daniCarouselPhotos {
    width: 100%;
  }

  .dandaAboutText {
    font-size: 1rem;
  }
}
