.FooterContainer {
  width: 100%;
  overflow: hidden;
  align-items: center;
  background-color: black;
}

video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.videoFooter {
  position: absolute;
  width: 100%;
  height: 10vh;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.footerContent {
  height: 100%;
  color: #f5deb3;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-left: 5%;
  padding-right: 2%;
}

.footerRight {
  text-align: end;
}

.footerLeft {
  text-align: start;
}

.logoImageFooter {
  width: 15%;
}

@media only screen and (max-width: 766px) {
  .FooterContainer {
    height: 35vh;
  }

  .footerContent {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .footerRight {
    text-align: center;
    order: 1;
  }

  .footerLeft {
    text-align: center;
    margin-bottom: 5%;
    order: 2;
  }

  .logoImageFooter {
    width: 40%;
  }
}
