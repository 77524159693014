.frameImagesContainer {
  height: 650px;
}

.dinamycImages {
  width: 900px;
  height: auto;
}

.blink {
  animation: blinking-image 1s linear;
}

@media only screen and (max-width: 921px) {
  .frameImagesContainer {
    height: 550px;
  }
  .dinamycImages {
    width: 750px;
  }
}

@media only screen and (max-width: 766px) {
  .frameImagesContainer {
    height: 450px;
  }
  .dinamycImages {
    width: 650px;
  }
}

@media only screen and (max-width: 660px) {
  .frameImagesContainer {
    height: 400px;
  }
  .dinamycImages {
    width: 550px;
  }
}

@media only screen and (max-width: 550px) {
  .frameImagesContainer {
    height: 300px;
  }
  .dinamycImages {
    width: 450px;
  }
}

@keyframes blinking-image {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
