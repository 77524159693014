.homePage {
  width: 100%;
  overflow: hidden;
}

.homeContent {
  animation: opacity-animation 2s ease-in-out;
}

.projectBox {
  padding: 0;
}

.grayscale {
  filter: grayscale(100%);
}

.link {
  padding: 0%;
  text-decoration: none;
  color: black;
}

.homeTitle {
  opacity: 0%;
  transition: opacity 500ms, background-color 500ms;
  height: 100%;
  color: wheat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.link:hover .homeTitle {
  opacity: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes opacity-animation {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .projectsSection {
    display: flex;
    flex-direction: column;
  }
}
