.contactPage {
  animation: opacity-animation 1s ease;
  height: 75vh;
  display: flex;
  justify-content: center;
}

.contact {
  color: wheat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact h4 {
  color: white;
}

.socialMediaSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.socialMediaIcons:hover {
  color: aquamarine;
}

.socialMediaIcons {
  width: 50%;
}

@media only screen and (max-width: 766px) {
  .contact {
    margin-top: 15%;
  }
}

@media only screen and (max-width: 1328px) {
  .contactPage {
    height: 80vh;
  }
}
