@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Rubik+Dirt&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Sedgwick+Ave+Display&family=VT323&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans+Collegiate+One&family=Amatic+SC&family=League+Spartan:wght@300&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Collegiate+One&family=Amatic+SC&family=Chakra+Petch&family=League+Spartan:wght@300&family=Rubik+Moonrocks&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Arvo&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Macondo&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Cardo&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Slab&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Rubik+Moonrocks&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gurajada&family=Josefin+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'VT323', monospace; */
}

/* .App {
  text-align: center;
  background-image: url(static/images/.jpg);
  background-size: cover;
  background-attachment: fixed;
} */

.App-header {
  width: 100%;
  font-family: "Gurajada", sans-serif;
}

body {
  background-color: rgba(0, 0, 0, 1);
}

main {
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  min-height: 75vh;
}

.Content {
  display: flex;
  justify-content: center;
  font-family: "Gurajada", sans-serif;
}

.footer {
  font-family: "VT323", monospace;
}

@media only screen and (max-width: 766px) {
  .App-header {
    position: fixed;
    z-index: 1;
  }

  .Content {
    position: relative;
    top: 7rem;
  }

  .footer {
    position: relative;
    top: 6rem;
    z-index: 1;
  }
}
