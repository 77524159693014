.ProjectContainer {
  color: wheat;
}

.project {
  animation: opacity-animation 1s ease;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 25px;
}

.clientParagraph {
  font-size: 1.3rem;
}

.iframeVideo {
  height: 60vh;
}

.projectDescription {
  height: 15vh;
  width: 65%;
}

.description {
  font-size: 1.5rem;
}

.ratio {
  width: 65%;
  height: 550px;
}

.projectVideo {
  height: 650px;
}

/* .carousel-inner {
  height: 450px;
} */

.photoContainer {
  margin-top: 5%;
  width: 80%;
  z-index: 0;
  height: 560px;
}

.photoImage {
  max-width: 1000px;
}

.errorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.errorPage h1 {
  color: wheat;
  margin-bottom: 50px;
  text-align: center;
}

.errorPage button {
  background-color: wheat;
  width: 200px;
  height: 40px;
  text-transform: uppercase;
  font-size: 1.5rem;
}

@media only screen and (max-width: 1328px) {
  .errorPage {
    height: 80vh;
  }
}

@media only screen and (max-width: 1256px) {
  .photoImage {
    max-width: 850px;
  }
}

@media only screen and (max-width: 1062px) {
  .photoImage {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .photoContainer {
    height: 350px;
  }

  .photoImage {
    max-width: 100%;
  }
}

@media only screen and (max-width: 660px) {
  .photoContainer {
    height: 320px;
  }
}

@media only screen and (max-width: 550px) {
  .photoContainer {
    height: 250px;
  }
}
