.NavBarContainer {
  background-color: black;
}

.languagueButtons {
  color: wheat;
}

.logoLink {
  width: 195px;
}

.globeIcon {
  color: white;
  position: absolute;
  right: 38px;
  top: 27px;
  z-index: 2;
}

.NavBarLinks {
  text-decoration: none;
  font-size: 2.5rem;
  color: #f5deb3;
  margin-left: 30px;
  font-weight: 400;
}

.NavBarLinks:hover {
  font-weight: 600;
}

.selected {
  font-weight: 600;
  color: rgb(255, 196, 86);
}

.logoImage {
  width: 120px;
}

.navbar {
  padding-left: 20px;
  padding-right: 20px;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.navBarMenu {
  z-index: 1;
}

.closeButton {
  position: absolute;
  right: 43px;
  top: 40px;
}

.logoWrapper {
  /* Control the size and position of the logo */
  transform: scale(1.7); /* Adjust the scale factor to make the image larger */
  transform-origin: left; /* Adjust the origin to keep it aligned with the left side */
}

.navbar-toggler {
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .NavBarLinks {
    margin: 0;
  }

  .globeIcon {
    right: 45px;
    top: 77px;
  }

  .es {
    padding: 0;
  }

  .en {
    padding-left: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .NavBarLinks {
    margin: 0;
  }

  .globeIcon {
    top: 70px;
  }

  .logoImage {
    width: 105px;
  }

  .logoLink {
    width: 160px;
  }
}
